<template>
	<div id="device-list" class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 40px">
			<div>
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder"> Devices </span>
				</h3>
			</div>
			<div class="card-toolbar">
				<div class="d-flex justify-content-end">
					<b-form-select class="sm" size="sm mr-4" v-model="selectedType" :options="deviceOptions"></b-form-select>
					<div class="search-box" style="margin-right: 15px">
						<b-form-input class="search-bar" size="sm" v-model="searchText" placeholder="Search" debounce="500" />
						<b-icon-search class="search-icon" />
					</div>
				</div>
			</div>
		</div>
		<div class="card-body pt-2 pb-0" style="max-height: 600px;">
			<ITCSpinner :loaded="loaded">
				<b-table
					:items="filteredDevices"
					:fields="[
						{ key: 'DeviceId', label: 'DeviceId', sortable: true },
						'TypeId',
						{ key: 'Name', label: 'Name', sortable: true },
						{ key: 'Status', label: 'Status' },
					]"
					:current-page="currentPage"
					per-page="14"
					show-empty
					outlined
					striped
					hover
					@row-clicked="rowClickHandler"
				>
					<template #cell(DeviceId)="data">
						{{ data.item.DeviceId }}
					</template>
					<template #cell(TypeId)="data">
						{{ deviceTypes[data.item.TypeId].Name }}
					</template>
					<template #cell(Name)="data">
						{{ data.item.Name }}
					</template>
					<template #cell(Status)="data">
						<span v-if="data.item.TypeId == 0" :class="['device-status-' + data.value.status, 'device-status-icon', 'mx-1']">{{
							data.value.status
						}}</span>
					</template>
				</b-table>
			</ITCSpinner>
		</div>
		<div class="footer" v-if="filteredDevices.length > 0 && devices.length > 14">
			<span>{{ devices.length }} devices</span>
			<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredDevices.length" per-page="14" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';

import swMixin from '@/core/services/mixins/serviceworker.mixin';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: 'DeviceList',
	mixins: [swMixin],
	data() {
		return {
			devices: [],
			searchText: '',
			currentPage: 1,
			loaded: false,
			selectedType: null,
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		...mapGetters(['deviceTypes']),
		deviceOptions() {
			var dt = [{ value: null, text: 'Select Device Type' }];

			Object.keys(this.deviceTypes).forEach(id => {
				dt.push({ value: id, text: this.deviceTypes[id].Name });
			});
			return dt;
		},

		filteredDevices() {
			if (this.selectedType) {
				if (!this.searchText) {
					return this.devices.filter(device => {
						return device.TypeId == this.selectedType;
					});
				} else {
					return this.devices.filter(device => {
						return (
							device.TypeId == this.selectedType &&
							device.Name.toString()
								.toLowerCase()
								.includes(this.searchText.toLowerCase())
						);
					});
				}
			}
			return this.devices.filter(device => {
				return device.Name.toString()
					.toLowerCase()
					.includes(this.searchText.toLowerCase());
			});
		},
	},
	methods: {
		rowClickHandler(r) {
			if (r.TypeId !== false) {
				if (![1, 2, 6, 8, 9, 14, 15, 16, 17, 18, 19].includes(r.TypeId)) {
					router.push({ name: 'admindevice', params: { id: r.DeviceId } });
				}
			}
		},
	},
	created() {
		this.loaded = false;
	},
	mounted() {
		this.$http.get('devices').then(res => {
			this.devices = Object.freeze(res.data.data);
			this.loaded = true;
		});
		this.setSWListener('devices', data => {
			this.devices = data.data;
		});
	},
};
</script>

<style scoped>
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
.device-status-icon {
	height: 15px;
	width: 15px;
	border-radius: 2px;
	padding: 5px;
}
.device-status-OK {
	background: #c9f7f5;
}
.device-status-ALARM {
	background: #f39ca3;
}
.device-status-DEACTIVATED {
	background: rgb(190, 185, 185);
}
.device-status-OFFLINE {
	background: #fef4de;
}
</style>
